import React, { useEffect, useState } from 'react';
import { fetchData } from './io';
import { workflowListViewStates } from 'constants/index';
import Loading from 'components/loading/Loading';
import { createWorkflowOptions, workflowNavigationInfo, workflowTabOptions } from 'assets/allData';
import { useNavigate } from 'react-router-dom';
import Helper from 'components/help/Helper';
import workflow from 'assets/img/workflow.png';
import ListView from './ListView';
import ListTabs from 'components/listTabs/ListTabs';
import LayoutView from 'components/LayoutView/LayoutView';
import GridView from './GridView';

const WorkflowList = ({ selected }) => {
  const [viewState, setViewState] = useState(workflowListViewStates.LOADING);
  const [workflowList, setWorkflowList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [layoutView, setLayoutView] = useState('list');
  const [selectedKinds, setSelectedKinds] = useState([]);

  const navigate = useNavigate();

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }

        const { workflows } = payload;

        setWorkflowList(workflows);
        setTableData(workflows);
        setViewState(workflowListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    let filteredDatas;
    filteredDatas = workflowList.filter(
      (listItem) =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableData(filteredDatas);
  }, [searchTerm, workflowList]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  useEffect(() => {
    if (selectedKinds.length === 0) {
      setTableData(workflowList);
    } else {
      let filteredDatas = [];
      selectedKinds.forEach((kind) => {
        filteredDatas = [
          ...filteredDatas,
          ...workflowList.filter((listItem) => listItem.kind === kind)
        ];
      });
      setTableData(filteredDatas);
    }
  }, [selectedKinds, workflowList]);

  // const handleSelectedList = (selectedFilters) => {
  //   setSelectedKinds(selectedFilters);
  // };

  if (viewState === workflowListViewStates.LOADING) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Helper
        message={
          'Workflows assist you in organizing and automating your tasks. Within workflows, there are three types of steps. The first type executes your commands, the second one parses the response of a selected step to provide the desired output, and the last type prints a response.'
        }
        infoIcon={true}
      />
      <ListTabs
        // filtersKind={workflowTabOptions}
        // onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        handleAddClick={() => navigate(`/workflows/new`)}
        // creatableTypeOptions={createWorkflowOptions}
      />
      {workflowList.length === 0 ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="flex justify-center">
            <img src={workflow} className="h-64 w-64" alt="create workflow" />{' '}
          </div>
          <div
            style={{
              maxWidth: '510px',
              margin: '0 auto'
            }}
            className="!my-6 text-center"
          >
            <Helper
              message={
                'Workflows assist you in organizing and automating your tasks. Within workflows, there are three types of steps. The first type executes your commands, the second one parses the response of a selected step to provide the desired output, and the last type prints a response..'
              }
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
          {layoutView === 'list' ? (
            <ListView
              list={tableData}
              rowClick={(item) => {
                const navigation = workflowNavigationInfo.find((navigationInfo) => {
                  return navigationInfo.type === item.kind;
                });
                if (item.kind === 'native' || !item.kind) {
                  navigate(`/workflows/edit/${item.name}`);
                } else {
                  navigate(`${navigation.createNewPath}/${item.name}`);
                }
              }}
            />
          ) : (
            <GridView
              list={tableData}
              rowClick={(item) => {
                const navigation = workflowNavigationInfo.find((navigationInfo) => {
                  return navigationInfo.type === item.kind;
                });
                if (item.kind === 'native' || !item.kind) {
                  navigate(`/workflows/edit/${item.name}`);
                } else {
                  navigate(`${navigation.createNewPath}/${item.name}`);
                }
              }}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WorkflowList;
